.gpt3__blog-container_article {
    border: 4px solid #23aeeb;
    margin-top: 1rem;
    max-width: 100%;
    height: 350px;
    display: flex;
    flex-direction: row;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    object-fit: contain;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image > img {
    object-fit: contain;
    height: 100%;
    max-width: 300px;
}

.blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 21.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 40.11px;
    font-weight: 800;
    line-height: 45.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 1200px) {
    .gpt3__blog-container_article {
        margin-top: 1rem;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .gpt3__blog-container_article-image img {
        height: 200px;
        width: 600px;
    }
    .blog-container_article-content p {
        font-size: 15px;
        line-height: 20px;
    }
    .blog-container_article-content h3 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10%;
    }
}
@media screen and (max-width: 450px) {
    .gpt3__blog-container_article {
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .gpt3__blog-container_article-image {
        padding: 1rem 1.5rem;
    }
    .gpt3__blog-container_article-image img {
        height: 100%;
        width: 100%;
    }
    .blog-container_article-content p {
        font-size: 12px;
        line-height: 20px;
    }
    .blog-container_article-content h3 {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 10%;
    }
}