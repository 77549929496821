.container-psdp {
    width: 100%;
    max-width: 1200px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 140px;
}

.container-psdp .inner-container-rcdp {
    border: none;
}

.container-psdp .content-inner-crcdp a {
    letter-spacing: 0.5px;
}

.container-psdp .content-inner-crcdp .desc-bgicrc {
    margin-bottom: 10px;
}

.circle-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 18px;
}

.circle-password {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: black;
}

.circle-password:last-child {
    margin-right: 0px;
}

.add-new-bgicrc {
    display: flex;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
}

.add-new-bgicrc label {
    cursor: pointer;
}

.circle-icon-anbg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    margin-right: 15px;
}

@media only screen and (max-width: 767px) {
    .container-psdp .inner-container-rcdp {
        margin-bottom: 15px;
    }

    .delaccount {
        position: static !important;
    }

    .container-psdp {
        padding-bottom: 20px;
    }
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: white;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}

.footer-on-detailedpage .product-details-wrapper * {
    color: black;
}

.footer-on-detailedpage .lds-default div {
    background: black;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}