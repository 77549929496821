.single__comp {
    background: #132347;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 10%;
    border-bottom: 2px solid #23aeeb;
    transition: 0.2s;
}
.single__comp:hover {
    background: #214088;
    transform: scale(1.04);
}

.single__icon > i {
    color: #23aeeb;
    font-size: 2rem;
}

.comp__top > h6 {
    color: #23aeeb;
    font-size: xx-large;
    margin-bottom: 15px;
}

.comp__top > h2 {
    color: gray;
    font-size: 1.6rem;
    margin-bottom: 15px;
}
.comp__top > p {
    font-weight: 300;
    margin-bottom: 30px;
}