.table {
    border: none;
    border-collapse: collapse;
    border: 1px solid #23aeeb;
    border-radius: 10px;

}

th, td {
    padding: 0.8rem 1rem;
    font-size: 1rem;
}

thead {
    background-color: #23aeeb;
    color: white;
}

td {
    font-size: 0.8rem;
    color: white;
    font-weight: 500;
}

tr:nth-child(even) {
    background: #102048;
}
