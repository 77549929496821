.hero__container {
    top: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 99;
    margin-bottom: 5%;
    
}

.hero__container > .hero {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

.hero__container > .hero > video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: .5;
    
}


.hero__content {
    z-index: 3;
    padding: 2%;
    background-color: black;
    border: 2px solid #25a2dc;
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    align-items: center;
}

.hero__content > p {
    color: #25a2dc;
    font-size: 1.2rem;
    align-items: center;
}
.hero__content > h1 {
    color: #25a2dc;
    flex-wrap: wrap;
    align-items: center;
}
.hero__content > h2 {
    flex-wrap: wrap;
    align-items: center;
}



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1000px) {
    .hero__container {
        width: 100%;
        margin-bottom: 0%;
        height: 200px;
    }
    

    .hero__container > .hero {
        width: auto;
    }

    .hero__container > .hero > .hero__content {
        margin-right: 8%;
        
    }
}
@media only screen and (max-width: 400px) {
    .hero__container {
        width: 100%;
        margin-bottom: 30%;
        height: 700px;
    }
}