.vertical-media__container {
    align-items: center;
    flex-direction: column;
    align-items: center;

}
.vetical-video__wrap{
    align-items: center;
    flex: 1;
    object-fit: contain;
    display: flex;
}

.vetical-video__wrap > video {
    align-items: center;
    flex: 1;
    object-fit: contain;
    height: 1000px;
}

@media screen and (max-width: 830px) {
    .vetical-video__wrap {
        margin-top: 8%;
        margin-bottom: -10%;
    }
}

@media screen and (max-width: 390px) {
    .vetical-video__wrap {
        margin-top: 20%;
    }
}