
.image__container > h3 {
    font-weight: 200;
    font-style: normal;
    background: var(--font-family);
    padding: 50px;
    background: black;
}
.image__container > h1 {
    font-weight: 200;
    font-style: normal;
    background: rgb(61, 61, 61);
    padding: 20px;
    margin: 10;
    width: 100%;
    flex: 2;
}


.image__container {
    margin-top: 10%;
}
.image__container > img {
    object-fit: contain;
    width: 95%;
}

.productimg__container {
    position: relative;
    margin-top: -10%;
}

.content {
    text-align: center;
}

.explanation__container {
    background-color: rgb(4, 29, 66);
    max-width: 100%;
    height: max-content;
    display: flex;
    margin: 10px;
    
}
.explanation__wrapper {
    width: 100%;
    padding: 0px 20px  0px;
    margin: 5% 5%;
    justify-content: center;
}

.explanation__wrapper > h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: var(--gradient-text);
    
}
.explanation__wrapper > h3 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;

}
.explanation__wrapper > p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.explanation__image {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    object-fit: contain;
    width: 3000px;
}
.explanation__image > img  {
    display: flex;
    width: 100%;
}
@media screen and (max-width: 1290px) {
    .productimg__container {
        margin-top: 10%;
    }
    .explanation  {
        font-size: medium;
        padding: 10%;
    }
    
}
@media screen and (max-width: 669px) {
    .productimg__container {
        margin-top: 20%;
        margin-bottom: -20%;
    }
    .explanation > p {
        font-size: 10px;
    }
    .explanation  {
        font-size: medium;
        padding: 20%;
    }
    
}