.blog {
    display: flex;
    flex-direction: column;    
    align-items: center;
    margin: 10rem;
}

.blog-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 5rem;
}

.blog-heading > h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.blog-container {
    flex-direction: row;
}


.blog-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.blog-container_groupB {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}



@media screen and (max-width: 1200px) {
    .blog {
        width: 100%;
        margin: 2rem 0rem 10rem;
        object-fit: contain;
    }
    .blog-container {
        flex-direction: column-reverse;
    }

    .blog-container_groupA {
        margin: 0rem 0rem 2rem ;
    }
    .gpt3__blog-container_groupB > img {
        height: 20%;
    }
    .blog-heading > h1 {
        font-size: xx-large;
    }
}

@media screen and (max-width: 500px) {
    .blog {
        width: 95%;
        display: flex;
    }
}
