.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f2f2f5;
}

.footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.footer-heading > h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}
.footer__buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 600px;
    text-align: left;
    font-size: 12px;
    color: #1d1d1f;
}
.footer__buttons p {
    color: #1d1d1f;
}

.footer-links div {
    width: 250px;
    margin: 2rem;
}
.footer-links_div > h6 {
    color: #1d1d1f;
}

.footer-links_logo p{
    margin-top: 1rem;
}
.footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #1d1d1f;
}

.footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 2rem;
}

.footer-links_div h4 {
    font-size: 15px;
    line-height: 17px;
    font-family: var(--font-family);
    color:#1d1d1f;

    margin-bottom: 0.9rem;
}

.footer-paragraph p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #1d1d1f;
}
.footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #1d1d1f;

    margin: 0.5rem 0;
    cursor: pointer;
}
.footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #1d1d1f;
}

@media screen and (max-width: 690px) {
    .footer {
        width: 100%;
    }
    .footer-heading > h1 {
        font-size: 30px;
        line-height: 38px;
    }
    .footer__buttons {
        width: 100%;
    }
    .footer-btn {
        width: 100%;
        padding: 0.5rem;
    }
    .footer-btn p{
        font-size: 16px;
    }
}