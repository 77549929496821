.hiw__container {
    padding: 20px;
}

.single__box {
    background-color: #003470;
    padding: 10px;
}

.hiw__title > span {
    font-weight: 300;
    color: #fff;
    opacity: 70%;
}
.hiw__description  {
    font-weight: 300;
    color: #fff;
    opacity: 70%;
}

.hiw__step {
    font-weight: 800;
    color: #23aeeb;
    opacity: 100%;
    font-size: 40px;
}