.single__testimonial {
    padding: 30px;
    background: #000;
    border-radius: 20px;
    border: 2px solid #23aeeb;
}
.single__testimonial > h6 {
    margin-bottom: 10%;
    color: gray;
}
.single__testimonial > div > h6 {
    margin-bottom: 10%;
    color: gray;
}