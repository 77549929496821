/* Info Container */
.info {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    padding: 4rem;

    margin: 6rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
/* Container */
.feature {
    display: flex;
}

.feature > div {
    margin: 1rem;
    display: unset;
    flex-direction: column;
}

.feature > div > text {
    max-width: 700px;
}

.feature {
    display: flex;
}

/* Main Header */
.heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 1rem;
}

/* Header */
.heading > h1 {
    font-size: 30px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

/* Orange Subtitle */
.info > a > p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
    margin: 1rem;
}

/* Services Container */
.container {
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
}
/* Individual Service Component */
.container > div {
    flex: 1;
    margin: 1rem;
}
/* Customizing feature component as per needs */
.container div > text {
    margin-top: 0.5rem;
}
/* Media Query */
@media screen and (max-width: 995px) {
    .info {
        display: flex;
        flex-direction: column;
        font-family: var(--font-family);
        padding: 3rem;

        margin: 0rem;
        background: var(--color-footer);
    }
    .heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
        font-size: medium;
    }
}


@media screen and (max-width: 390px) {
    .info {
        width: 100%;
        display: flex;
        margin-top: 20%;
    }
}
