.scroll {
    display: flex;
    overflow-x: auto;
    margin-top: 3%;
    height: 700px;
}
.scroll::-webkit-scrollbar {
    width: 100%;
    display: none;

}
.scroll__card {
    margin: 5px;
}

.scroll__card:hover {
    transform: scale(1.04);
    animation-duration: 3ms;
}

.scroll__content {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 91;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.scroll__content a {
    color: #23aeeb;
    text-decoration: none;
    font-size: 0.9rem;
}

.scroll__content h5 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: black;

}

.scroll__img > img {
    object-fit: contain;
    width: 100%;
    border-radius: 10%;
}
.content__top {
    margin: 20px;
}


.content__top > h6 {
    color: gray;
}

@media screen and (max-width: 1300px) {
    .scroll {
        border: none;
        display: flex;
        overflow-x: auto;
        height: 500px;
    }
    
}

@media screen and (max-width: 565px) {
    .scroll {
        border: none;
        display: flex;
        overflow-x: auto;
        height: 620px;
        flex-direction: column;
    }
    .scroll::-webkit-scrollbar {
        display: none;
    }
    .scroll__card {
        margin: 5px;
    }
    
    .scroll__card:hover {
        transform: scale(1.03);
        animation-duration: 3ms;
    }
    
    .scroll__content {
        width: 100%;
        top: 0;
        left: 0;
        z-index: 91;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    
}