.portfolio__top {
    color: #23aeeb;
    margin-bottom: 15px;
    
}


.portfolio__card {
    position: relative;
    z-index: 90;
    margin-bottom: 25%;
    background: #214088;
}
.portfolio__card:hover {
    transform: scale(1.09);
    animation-duration: 2ms;
    background: #3874ff94;
}

.portfolio__content {
    width: 100%;
    height: 300px;
    top: 0;
    left: 0;
    z-index: 91;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(rgb(0, 13, 107, 0.6), rgba(1, 22, 117, 0.6));
}

.portfolio__content a {
    color: #23aeeb;
    text-decoration: none;
    font-size: 0.9rem;
}

.portfolio__content h5 {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 10px;
}
.pcontent__top > h6 {
    color: rgb(177, 176, 176);
    font-size: 20px;
    margin-bottom: 15px;
}

.portfolio__img > img {
    object-fit: contain;
    width: 100%;
}

