@media screen and (max-width: 995px) {   
    .ty__img {
    display: flex;
    height: 800px;
    width: 800px;
    }   
}
@media screen and (max-width: 715px) {   
    .ty__img {
    display: flex;
    width: 100%;
    height: 100%;
    }   
}