/*  Capabilities container */
.capabilities {
    display: flex;
    flex-direction: row;
}

/* Main Image  */
.capabilities-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.capabilities-image > img {
    width: 70%;
    object-fit: contain;
}
/* Content container */
.capabilities-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.capabilities-content > h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.capabilities-content > h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.capabilities-content > p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

/* Media Query */

@media screen and (max-width: 400px) {
    .capabilities-content > h4 {
        margin-top: 10rem;
    }
}
@media screen and (max-width: 900px) {
    .capabilities {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .capabilities-image {
        margin: 1rem 0;
    }

    .capabilities-image > img {
        width: unset;
        height: unset;
    }
    .capabilities-content > div {
        margin-bottom: 50%;
    }
    .capabilities-image > img {
        width: 100%;
        height: 100%;
    }
}



@media screen and (max-width: 1250px) {
    .capabilities-content{
        flex-direction: column;
        display: flex;
    }
    .capabilities-image > img {
        width: 100%;
        height: 100%;
    }
    .capabilities-content {
        margin: 3;
    }
}

