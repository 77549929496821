.media__container {
    background-color: rgb(4, 29, 66);
    max-width: 100%;
    height: max-content;
    display: flex;
    
}
.media__wrapper {
    width: 100%;
    padding: 0px 20px  0px;
    margin: 5% 5%;
    justify-content: center;
}

.media__wrapper > h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: var(--gradient-text);
    
}
.media__wrapper > h3 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;

}
.media__wrapper > p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.media__wrapper > h5 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: rgb(164, 137, 214);
    margin-top: 1.5rem;
}
.media__wrapper > button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #ffffff;
    border: 2px solid #000;
    padding: 0 1rem;
    color: #000;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}
.image__wrapper {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    object-fit: contain;
    padding: 1%;
    width: 3000px;
}
.image__wrapper > img  {
    display: flex;
    width: 100%;
}

/* Media Query */
@media screen and (max-width: 1872px) {
    .image__wrapper > video  {
        width: 100%;
    }
}
@media screen and (max-width: 1272px) {
    .media__container {
        margin-top: 11%;
    }
    .image__wrapper > video  {
        width: 100%;
    }
}
@media screen and (max-width: 872px) {
    .media__container {
        width: 100%;
        align-items: center;
        flex-direction: column;
        height: max-content;
        margin-top: 10%;
    }
    .image__wrapper {
        width: 100%;
        height: fit-content;
    }
    .image__wrapper > img  {
        width: 100%;
    }
    .image__wrapper > video  {
        width: 100%;
    }
    .media__wrapper > h1 {
        font-size: 49px;
    }
    .media__wrapper > p {
        font-size: 20px;
        width: 90%;
    }
    
}

@media screen and (max-width: 590px) {
    .media__container {
        max-width: 100%;
        margin: 25% 1%;
        
    }
    .media__wrapper > h1 {
        font-size: 40px;
    }
    .media__wrapper > p {
        font-size: 15px;
        width: 100%;
    }
    .image__wrapper > video  {
        width: 100%;
    }
}
@media screen and (max-width: 290px) {
    .media__container {
        max-width: 100%;
        margin: 25% 1%;
    }
    .image__wrapper > video  {
        width: 100%;
    }
    .media__wrapper > h1 {
        font-size: 40px;
    }
    .media__wrapper > p {
        font-size: 15px;
        width: 100%;
    }
}