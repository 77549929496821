.legal {
    background: white;
}
.legal__banner {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 2;
}
.legal__bg {
    display: flex;
}
.legal__sm {
    display: none;
}
.text {
    padding: 4%;
    margin-top: 2%;
}
.text > h1 {
    width: 100%;
    display: flex;
    color: black;
    margin-bottom: 5%;
}
.text > h3 {
    width: 100%;
    display: flex;
    color: black;
    margin-bottom: 5%;
}
.text > p {
    width: 100%;
    display: flex;
    margin-bottom: 5%;
    color: gray;
}
.text > h6 {
    width: 100%;
    display: flex;
    color: #06c;
    margin-bottom: 5%;
}


@media screen and (max-width: 1810px) {
    .text {
        margin-top: 5%;
    } 
    .legal__bg{
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
    }
    .legal__sm {
        display: none;
    }
}
@media screen and (max-width: 960px) {
    .text {
        margin-top: 15%;
    } 
    .legal__sm {
        width: 500px;
        height: 100%;
        display: flex;
    }
    .legal__bg {
        display: none;
    }
}
@media screen and (max-width: 560px) {
    .text {
        margin-top: 15%;
    } 
    .legal__sm {
        width: 300px;
        height: 100%;
    }
    .legal__bg {
        display: none;
    }
}
@media screen and (max-width: 460px) {
    .text {
        margin-top: 1%;
    } 
    .legal__sm {
        width: 300px;
        height: 400px;
    }
    .legal__bg {
        display: none;
    }
}
@media screen and (max-width: 300px) {
    .text {
        margin-top: 1%;
    } 
    .legal__sm {
        width: 200px;
        height: 300px;
    }
    .legal__bg {
        display: none;
    }
}
