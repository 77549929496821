.lead {
    min-height: 220vh;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-family: sans-serif;
    background: #12c2e9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff0011, #aa00ff, #0073ff);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffb62e, #aa00ff, #03a4c8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.leadform {
  width: 650px;
  margin-top: 7%;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
    
}

.leadform p {
    font-size: 10px;
    font-weight: 300;
    color: gray;
    align-items: center;
}
.leadform h3 {
    font-size: 34px;
    line-height: 45px;
    letter-spacing: 0.6px;
    font-weight: 500;
    padding-bottom: 10px;
    color: black;
}

.lead-form-parent {
    width: 100%;
    max-width: 332px;
    margin: 0px auto;
}

.convert-forms {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.convert-forms label {
    color: #5d5c61;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.414;
    padding-left: 10px;
    height: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
}

.lead-boxes-holder {
    width: 100%;
    position: relative;
}

.lead-boxes-holder svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
    font-size: 20px;
    fill: black;
    color: black;
}

.convert-forms input, .convert-forms select {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid #f5f5f5;
    font-weight: 500;
    outline-color: #dddcdc;
    width: 100%;
    color: black;
}

.convert-forms select {
    appearance: none;
}

.tooltip-icon {
    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width='50px' height='50px'%3E%3Cpath d='M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z'/%3E%3C/svg%3E");
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    margin-left: 5px;
}

.open-account-button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: white;
    background-color: #3168ff;
    border-radius: 32px;
    border: none;
    outline: none;
    margin: 0px auto;
    margin-top: 20px;
    width: 100%;
    display: block;
    padding: 7px 24px;
}

.open-account-button:hover {
    background-color: #3d69e1;
    color: white;
}

.separator-main {
    width: 100%;
    display: flex;
    margin-left: 5%;
    align-items: center;
    margin-top: 10px;

    margin-bottom: 10px;
}

.separator-main label {
    font-size: 15px;
    font-weight: 500;
    color: rgb(62, 62, 62);
}

.horizontal-line {
    width: 40%;
    height: 1px;
    margin: 0 8px;
    background-color: rgb(147, 147, 147);
}

.login-button{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgb(227, 0, 0);
    background-color: white;
    border-radius: 32px;
    border: 1px solid black;
    width: 100%;
    display: block;
    padding: 7px 20px;
    transition: all .3s ease-in-out;
    color: black;
}

.login-button:hover {
    background-color: rgb(202, 202, 202);
    color: black;
}

.disclaimer {
    align-items: center;
    text-align: right;
  }
  .notice p,
  .notice a {
    text-align: left;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
@media only screen and (max-width: 800px) {
    .leadform > img {
        width: 200px;
        height: 200px;
        align-items: center;
        justify-content: center;
    }
    .leadform {
        width: 100%;
        max-width: 90%;
        margin-top: 15%;
    }

    .lead-form-parent {
        max-width: 100%;
    }

    .leadform h3 {
        font-size: 22px;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}