.super__section {
    margin-top: 2rem;
    padding: 5rem;
}

/* Title CSS */
.super__title span {
    background: var(--gradient-color);
}
.super__title  {
    font-weight: 600;
}

.super__img  {
    height: max-content;
    
}
.super__img > img {
    object-fit: contain;
    width: 500px;
    height: 500px;
    border-radius: 10px;
    margin-left: auto;
}

.super__content > p {
    font-weight: 300;
    opacity: 0.7;
}

/* Button 1 CSS */
.btn-contact_btn {
    border-radius: 10px;
    background: #fff;
    color: black;
    font-weight: 600;
    padding: 0.5rem 1rem;
}
.btn-contact_btn:hover {
    background-color:rgb(223, 223, 223);
    transform: scale(1.02)
    
}


/* Button 2 CSS */
.btn {
    background-color: #0095ff;
    font-family: var(--font-family);
    font-weight: 600;
    padding: 0.5rem 1rem;
}
.btn:hover {
    background-color: rgb(55, 175, 255);
    transform: scale(1.02)
}

@media screen and (max-width: 1090px) {
    
    .super__img  {
        height: max-content;
        width: 100%;
        
    }
    .super__img > img {
        object-fit: contain;
        width: 300px;
        height: 300px;
        border-radius: 10px;
        margin-left: auto;
        
    }
}
@media screen and (max-width: 390px) {
    
    .super__img  {
        height: max-content;
        
    }
    .super__img > img {
        object-fit: contain;
        width: 200px;
        height: 100%;
        margin-top: 20%;
        border-radius: 10px;
        margin-left: auto;
        
    }
    .row__content{
        padding-right: -20%;
    }
    .super__content {
        width: 200px;
        font-size: small;
    }
    .super__btns {
        margin-right: 20%;
        flex-direction: column-reverse;
    }
}