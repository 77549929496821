.left-dashboard-main ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.left-dashboard-main ul li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.round-circle-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.left-dashboard-main ul li.active {
    font-weight: 500;
}

.left-dashboard-main ul li.active .round-circle-icon {
    background-color: #f6f6f6;
    color: black !important;
}

.left-dashboard-main ul li.active .round-circle-icon * {
    color: black !important;
}


@media only screen and (max-width: 767px) {
    .container-holder-mainldm {
        display: flex;
        align-items: center;
    }

    .container-holder-mainldm label {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    .dropdown-mobile-icon {
        font-size: 28px;
        margin-left: 8px;
    }

    .menu-bar-complete {
        position: fixed;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100vh;
        left: 0px;
        right: 0px;
        z-index: 9999;
        background-color: white;
        display: flex;
        align-items: center;
        padding-left: 20px;
        /* justify-content: center; */
        transition: all .3s ease-in-out;
    }

    .menu-bar-complete * {
        color: black;
    }

    .close-grbc {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 22px;
    }

    .container-holder-mainldm .round-circle-icon {
        background-color: #f6f6f6;
    }

    .container-holder-mainldm .round-circle-icon {
        width: 40px;
        height: 40px;
        font-size: 22px;
    }

    .container-holder-mainldm .round-circle-icon * {
        color: black;
    }

    .left-dashboard-main .menu-bar-complete ul li * {
        color: black;
    }
}