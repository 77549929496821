.page  {
    margin-top: 2%;
    margin-bottom: 2%;
}
.space {
    padding: 6%;
}
@media screen and (max-width: 609px) {
    .large__video{ 
        margin-bottom: -20%;
    }
    
}
