.sitemap {
    display: flex;
    flex-direction: column;
    background: whitesmoke;
    width: 100%;
}

.sitemap__container {
    align-items: center;
    margin: 5rem;
}

.sitemap__header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    z-index: 9997;
    border-bottom: 1px solid #000;
    margin-bottom: 2rem;
}
.sitemap__header > h1 {
    color: black;
    font-size: 25px;
}
.sitemap-links__div > h4 {
    color: black;
}


.sitemap-links__div > p > a{
    color: #06c;
}

@media screen and (max-width: 468px) {
    .sitemap__container {
        align-items: center;
        margin: 5rem;
    }
    
    .sitemap__header {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 52px;
        z-index: 9997;
        border: none;
    }
    .sitemap__header > h1 {
        color: black;
        font-size: 1.3rem;
        

    }
    .sitemap-links__div > h4 {
        color: black;
        width: 100%;
        font-size: 1rem;
        width: 200px;
    }
    .sitemap-links__div > p > a{
        color: #06c;
        font-size: 1rem;
        width: 200px;
    }
    
    
    
}