.connectbankimage {
    background-image: url(../../assets/bank-connection.jpg);
}

.creditcardimage {
    background-image: url(../../assets/credit-card.jpg);
}

.paymentmethods-container .inner-container-rcdp button {
    background-color: blue;
    padding: 8px 12px;
    width: 100%;
    margin-top: 25px;
    border: none;
    outline: none;
    color: white;
    border-radius: 20px;
}

.paymentmethods-container .content-inner-crcdp {
    padding: 10px;
}