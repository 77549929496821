.product-details-wrapper {
    width: 100%;
    max-width: 1500px;
    min-height: 100vh;
    padding: 50px 0px;
    padding-bottom: 110px;
    /* background-color: palegoldenrod; */
    margin: 0px auto;
    display: flex;
    align-items: center;
}

.footer-on-detailedpage .gpt3__footer {
    padding-bottom: 210px;
}


.left-pdw-main {
    width: 100%;
    max-width: 750px;
    padding-left: 120px;
    padding-right: 120px;
}

.left-pdw-main img {
    max-width: 100%;

}

.right-pdw-main {
    flex-grow: 1;
    /* background-color: black; */
}

.right-pdw-main h3 {
    font-size: 44px;
    margin-bottom: 20px;
}

.right-pdw-main p {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 17px;
}

.anchor-right-main {
    color: rgb(123, 123, 255);
    font-size: 28px;
    margin-top: 25px;
    font-size: 20px;
    display: flex;
    align-items: center;
}

.anchor-right-main svg {
    font-size: 14px;
    margin-left: 10px;
}

.etheware-trade-in {
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
    border-top: 1px solid #cac9c9;
    padding-bottom: 15px;
}

.etheware-trade-in p:nth-child(2) {
    color: rgb(109, 109, 109);
    font-weight: 400;
}

.etheware-trade-in a:nth-child(3) {
    color: rgb(123, 123, 255);
    font-weight: 500;
    font-size: 17px;
}

.bottom-buy-rpdm {
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
    border-top: 1px solid #cac9c9;
}

.bottom-buy-rpdm button {
    background-color: rgb(123, 123, 255);
    color: white !important;
    border: none;
    outline: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 8px 20px;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}

.bottom-buy-rpdm button:hover {
    background-color: black;
}

.view-gallery-link {
    color: rgb(123, 123, 255) !important;
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
    cursor: pointer;
    font-weight: 500;
}

.view-gallery-slider {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.close-view-gallery {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 9999;
    font-size: 28px;
}

.view-gallery-slider .carousel {
    width: 100%;
}

.view-gallery-slider img {
    max-width: 600px;
    max-height: 600px;
    margin: 0px auto;
    object-fit: cover;
    object-position: center;
}

.view-gallery-slider .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}

.view-gallery-slider .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.view-gallery-slider .carousel-indicators {
    bottom: -60px;
}

.view-gallery-slider .carousel-indicators [data-bs-target] {
    background-color: black;
}

.tabs-info-detailed {
    border-bottom: 1px solid #cac9c9;
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin: 0px auto;
    margin-top: 40px;
    align-items: flex-start;
}

.inner-tidetailed {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.inner-tidetailed svg {
    font-size: 32px;
    margin-bottom: 10px;
}

.inner-tidetailed p {
    font-weight: 500;
}

.chat-info-detailed {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.chat-info-detailed svg {
    font-size: 32px;
    margin-right: 15px;
}

.chat-info-detailed p {
    margin-bottom: 0px;
    text-align: right;
}

.chat-info-detailed div p:nth-child(2) {
    color: rgb(123, 123, 255);
    cursor: pointer;
}

.chat-info-detailed div p:nth-child(2) * {
    color: rgb(123, 123, 255);
    cursor: pointer;
}

.chat-info-detailed div p:nth-child(2) a {
    color: rgb(123, 123, 255) !important;
    cursor: pointer;
}

.sticky-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    min-height: 130px;
    background-color: #f8f8f8;
    left: 0px;
    border-top: 1px solid #d6d6d6;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    color: black;
}

.sticky-footer * {
    color: black;
}

.footer-container {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
}

.advanced-footer-main {
    width: 100%;
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.advanced-footer-main svg {
    font-size: 32px;
    margin-right: 15px;
}

.advanced-footer-main p {
    margin-bottom: 0px;
}

.advanced-footer-main div p:nth-child(2) {
    color: rgb(123, 123, 255);
    cursor: pointer;
}

.pricing-footer-main {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pricing-footer-main p {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: right;
}

.pricing-footer-main button {
    margin-left: 30px;
    background-color: rgb(123, 123, 255);
    color: white !important;
    border: none;
    outline: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 8px 20px;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}

.pricing-footer-main button a {
    color: white !important;
}

.pricing-footer-main button:hover {
    background-color: black;
}

.blue-inner-pfm {
    color: rgb(123, 123, 255);
    font-size: 16px !important;
}

.perks-products-main {
    width: 100%;
    display: flex;
    border-top: 1px solid #d6d6d6;
    padding-top: 30px;
    padding-bottom: 30px;
}

.inner-ppm {
    width: 33%;
    text-align: center;
}

.inner-ppm h4 {
    font-size: 32px;
    font-weight: 500;
}

.inner-ppm p {
    color: #cac9c9;
    font-weight: 500;
}

.product-details-more {
    border-top: 1px solid #d6d6d6;
}

.inner-pdm-main {
    margin-top: 30px;
}

.inner-pdm-main h4 {
    color: #adadad;
    margin-bottom: 20px;
}

.inner-pdm-main p:last-child {
    margin-bottom: 0px;
    color: #cac9c9;
}

.round-inner-pdm {
    width: 100%;
    padding: 20px 30px;
    border-radius: 50px;
    border: 5px solid #cac9c9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    color: white;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.round-inner-pdm.active {
    border-color: rgb(123, 123, 255);
    border-width: 5px;
}

.round-inner-pdm.active p:last-child {
    color: black;
}

.product-details-more .round-inner-pdm p {
    /* color: white !important; */
    font-size: 22px;
    font-weight: 700 !important;
    letter-spacing: 0.4px !important;
}

.round-inner-pdm p {
    margin: 0px;
}

.pre-installed-worker {
    border-top: 1px solid #d6d6d6;
    padding-top: 30px;
    margin-top: 30px;
}

.pre-installed-worker h4 {
    font-size: 28px;
    font-weight: 600;
}

.inner-piwk {
    margin-top: 25px;
}

.inner-piwk p:first-child {
    font-size: 20px;
    margin-bottom: 2px;
}

.inner-piwk p:nth-child(2) {
    font-size: 18px;
    margin-bottom: 0px;
    color: rgb(123, 123, 255);
}

.selection-ipiwk {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    margin-top: 20px;
}

.inner-spiwk {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid #cac9c9;
    border-radius: 15px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

/* .inner-spiwk p:nth-child(2) {
    color: white;
} */

.inner-spiwk.active {
    border-color: rgb(123, 123, 255);
}

.inner-provider-main {
    width: 100%;
    padding: 20px;
    background-color: #eceaea;
    margin-top: 20px;
    border-radius: 10px;
}

.inner-provider-main p:nth-child(1) {
    font-size: 20px;
    color: black;
    margin-bottom: 20px;
}

.inner-provider-main p {
    font-size: 18px;
    margin-bottom: 0px;
    color: #979797;
}

.bluecolortext {
    color: rgb(123, 123, 255) !important;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.bluecolortext svg {
    font-size: 15px;
    margin-left: 8px;
}

.inner-provider-main select {
    width: 100%;
    padding: 10px 15px;
    background-color: transparent;
    margin-top: 5px;
    border-radius: 8px;
    outline: none;
    appearance: none;
}

.select-position {
    width: 100%;
    position: relative;
}

.select-position svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    font-size: 22px;
}

@media only screen and (max-width: 1100px) {
    .pricing-footer-main p {
        font-size: 22px;
    }

    .advanced-footer-main {
        max-width: 250px;
    }
}

@media only screen and (max-width: 900px) {
    .product-details-wrapper {
        flex-direction: column;
        width: 80%;
        margin: 0px auto;
    }

    .left-pdw-main {
        margin-bottom: 40px;
    }

    .view-gallery-link {
        font-size: 18px;
    }

    .left-pdw-main, .right-pdw-main {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    .right-pdw-main h3 {
        font-size: 22px;
    }

    .right-pdw-main p {
        font-size: 13px;
    }

    .view-gallery-slider img {
        max-width: 80%;
    }

    .close-view-gallery {
        font-size: 22px;
    }

}


@media only screen and (max-width: 767px) {

    .footer-container {
        flex-wrap: wrap;
    }

    .advanced-footer-main {
        max-width: 50%;
    }

    .pricing-footer-main {
        max-width: 100%;
        margin-top: 15px;
        justify-content: center;
    }

    .perks-products-main {
        flex-direction: column;
    }

    .inner-ppm {
        width: 100%;
        text-align: left;
    }

    .inner-piwk p:first-child {
        font-size: 16px;
    }

    .inner-piwk p:nth-child(2) {
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .product-details-wrapper {
        width: 90%;
        padding-bottom: 270px;
    }

    .footer-on-detailedpage .gpt3__footer {
        padding-bottom: 270px;
    }

    .footer-container {
        width: 90%;
        margin: 0px auto;
    }

    .pricing-footer-main p {
        font-size: 15px;
    }

    .advanced-footer-main div p {
        font-size: 13px;
    }

    .blue-inner-pfm {
        font-size: 15px !important;
    }

    .tabs-info-detailed {
        width: 100%;
        flex-direction: column;
    }

    .inner-tidetailed {
        width: 100%;
    }

    .inner-tidetailed:first-child {
        margin-bottom: 10px;
    }

    .chat-info-detailed p {
        text-align: left;
    }

    .product-details-more .round-inner-pdm p {
        font-size: 18px !important;
    }

    .product-details-more .round-inner-pdm {
        padding: 20px !important;
    }
}

.right-pdw-main::-webkit-scrollbar {
    display: none;
}

@media only screen and (min-width: 768px) {
    .right-pdw-main {
        max-height: 80vh;
        overflow-y: auto;
        padding-bottom: 100px;
    }
}

.overlay-show-trade {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-ostrade {
    width: 90%;
    max-width: 600px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    color: black;
    padding-top: 50px !important;
    position: relative;
}

.container-ostrade svg {
    color: black !important;
    fill: black !important;
    margin-left: auto !important;
    margin-bottom: 10px !important;
    font-size: 22px !important;
    align-self: flex-end;
    position: absolute;
    top: 10px;
    right: 10px;
}

.container-ostrade p {
    color: black !important;
    margin-bottom: 0px !important;
}