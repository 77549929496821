.single__info-box {
    background: #132347;
    padding: 20px;
    border-radius: 5px;
    border-bottom: 1px solid #7865ff;
    margin-bottom: 35px;

}
.single__info-box > p {
    font-weight: 300;
    margin-top: 10px;
    font-size: 0.9rem;
    margin-bottom: 0;
    overflow: hidden;

}
.single__info-box h6 {
    font-size: 1.1rem;
}

@media screen and (max-width: 590px) {
    col > .contact__info-container {
       display: none;
    
    }
    
}