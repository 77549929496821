.display {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;

}
.display__img {
    display: flex;
    position: relative;
    display: flex;
    top: 50%;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.display__img > img {
    object-fit: contain;
    width: 100%;
}
.display__text {
    background-color: black;
    height: 1000px;
    position: relative;
    display: flex;
    top: 50%;
    align-items: center;
    flex-direction: column;
    width: 100%;
   
}

.text__container h1 {
    color: #ff7700;
    font-size: 98px;
    width: 900px;
    font-weight: 800;
}
.text__container > a > p{
    width: 100%;
    position: relative;
    color: white;
}
.text__container {
    font-size: 64px;
    font-weight: 700;
    position: absolute;
    padding: 10%;
    display: flex;
    top: 50%;
    align-items: center;
    flex-direction: column;
    width: 100%;
    letter-spacing: -.0045em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

@media screen and (max-width: 1000px) {
    .text__container h1 {
        width: 650px;
        font-size: 80px;
    }
    .text__container > a > p{
        position: relative;
        width: 100%;
        
        color: white;
    }
    .text__container {
        font-size: 60px;
        font-weight: 700;
        position: absolute;
        display: flex;
        top: 50%;
        align-items: center;
        flex-direction: column;
        width: 100%;
        letter-spacing: -.0045em;
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
}
@media screen and (max-width: 650px) {
    .text__container h1 {
        width: 400px;
        font-size: 50px;
    }
    .text__container > a > p{
        position: relative;
        font-size: 45px;
        
        color: white;
    }
}

@media screen and (max-width: 450px) {
    .text__container {
        width: 100%;
    }
    .text__container > h1 {
        font-size:25px;
        overflow: hidden;
        width: 100%;
    }
    .text__container > a > p{
        font-size: 25px;
    }
    
}