/* Styling for the Mutli Colored backgrounds, main difference is the color of the text. */
.mini__module {
    display: flex;
    border: 8px solid white;
    width: 100%;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex: 2;
    border-top: 2.5px solid whitesmoke;
    border-bottom: 2.5px solid whitesmoke;
}

.mini-module__bg {
    display: flex;

}

.mini-module__img {
    max-width: 5000px;
    align-items: center;
    justify-content: center;
    flex: 2;
    border-top: 2.5px solid whitesmoke;
    border-bottom: 2.5px solid whitesmoke;

}

.mini-module__img > a > img {
    display: block;
    width: 50%;
    object-fit: contain;
}

/* Styling for the Mutli Colored backgrounds, main difference is the color of the text. */
.mini__module2 {
    display: flex;
}

.mini-module__bg2 {
    display: flex;
}

.mini-module__img2 {
    max-width: 5000px;
    align-items: center;
    justify-content: center;
    flex: 2;
    border-top: 2.5px solid whitesmoke;
    border-bottom: 2.5px solid whitesmoke;
}

.mini-module__img2 > a > img {
    display: block;
    width: 50%;
    object-fit: contain;
    max-width: 5000px;
    align-items: center;
    justify-content: center;
    flex: 2;
    border-top: 2.5px solid whitesmoke;
    border-bottom: 2.5px solid whitesmoke;
}


/* Media Query */
@media screen and (max-width: 1732px) {
    .mini__module {
        width: 100%;
        object-fit: contain;
    }
    
    .mini-module__bg {
        display: flex;
        flex-direction: column;
    }
    .mini__module1 {
        display: flex;
        width: 100%;
    }
    .mini-module__img {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 2;
        border-top: 2.5px solid whitesmoke;
        border-bottom: 2.5px solid whitesmoke;
    
    }
    .mini-module__img {
        display: flex;
        object-fit: contain;
        width: 100%;
    }
    .mini__module2 {
        display: flex;
        flex-direction: column;
        border: 2px solid white;
        width: 100%;
    }
    
    .mini-module__bg2 {
        display: flex;
    
    }
    
    .mini-module__img2 {
        display: flex;
        object-fit: contain;
        width: 100%;
    }
    
    .mini-module__img2 > a > img {
        display: block;
        width: 100%;
        
    }
}
