.video__wrap {
    width: 100%;
    flex: 1;
    object-fit: contain;
    display: flex;
    margin-bottom: -10%;
}

@media screen and (max-width: 830px) {
    .video__wrap {
        margin-top: 8%;
        margin-bottom: -10%;
    }
}

@media screen and (max-width: 390px) {
    .video__wrap {
        margin-top: 20%;
    }
}