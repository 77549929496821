.preorder {
    align-items: center;
    display: flex;
    padding: 5rem;
    flex-direction: column;
}

@media screen and (max-width: 768) {
    .preorder {
        flex-direction: row;
        display: flex;
    }
    
}