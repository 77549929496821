.terms-of-service {
    padding: 5%;
    background: whitesmoke;
}
.terms-of-service > h1{
    font-size: 60px;
    color: #000;
    align-self: center;
    justify-content: center;
    display: flex;
    
}
.text > h5 {
    color: rgb(0, 0, 0);
}
.text > p {
    color: rgb(160, 153, 153);
}
.terms-of-service > h6 {
    color: #000;
    align-self: center;
    justify-content: center;
    display: flex;
}
.terms-of-service > .text {
    margin: 4rem;
}

button {
    right: 5rem;
}
a > button {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    padding: 0.5rem 1rem;
    min-width: 150px;
    border: 2px solid black;
    border-radius: 25px;
    color: black;
}
a > button:hover {
    transform: scale(1.02);
    background: rgb(146, 146, 146);
}
a > button > p > a:hover {
    color: white;
}

@media screen and (max-width: 1168px) {
    a > button {
        margin-top: 10%;
        font-size: 15px;
        padding: 0.2rem 5rem;
        min-width: 100px;
    }
    
}