.contact {
    align-items: center;
}
.contact {
    text-align: center;
    width: 100%;
    padding: 10%;
    background: url(./../../assets/colorblend.png);
}

.header > img {
    object-fit: contain;
    display: flex;
    left: 100;
}


