.main-orders-page .bg-inner-crcdp {
    height: 200px;
}

.main-orders-page .container-rcdp {
    max-width: 1300px;
}

.main-orders-page .container-rcdp {
    max-width: 1300px;
}

.main-orders-page .bg-inner-crcdp {
    height: 500px;
}