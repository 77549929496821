/* Features Container */
.features {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 6rem;
    padding: 6rem;
}

/* Text Container */
.features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

/* Topline */
.features-heading > p {
    line-height: 30px;
    font-weight: 700;
    font-family: var(--font-family);
}

/*  Main Header */
.features-heading > h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}
/* Paragraph  */
.features-heading > h2 {
    color: var(--color-subtext);
    line-height: 50px;
    font-weight: 400;
    font-family: var(--font-family);
    margin-top: 2rem;
    font-size: 20px;
}
/* Services Container */
.features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

/* Media Query */
@media screen and (max-width: 990px) {
    .features {
        flex-direction: column;
    }

    .features-heading {
        margin: 0 0 2rem 0;
        font-size: smaller;
    }
    .features-heading > h1 {
        margin: 0rem 10rem 2rem 0;
        font-size: x-large;
        width: 100%;
    }
    .features-heading > h2 {
        margin: 0 0 2rem 0;
    }
    .features__container > h2 {
        text-overflow: clip;
    }
}


@media screen and (max-width: 450px) {
    .features-heading > h1 {
        font-size: 28px;
        line-height: 38px;
    }
    .features-heading {
        margin: 0 0 2rem 0;
        font-size: smaller;
    }
    .features-heading > h2 {
        margin: 0 0 2rem 0;
    }
}