.single__execution-content {
    padding: 30px;
    background: #232e3b;
    border-radius: 10px;
    border: 3px solid rgb(216, 232, 243);
    margin-bottom: 5%;
}

.single__execution {
    position: relative;
    padding: 10px 30px;
    z-index: 79;
    
}
.single__execution::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    content: '';
    background: #23aeeb;
    color: #23aeeb;

}
.execution__icon > i {
    position: absolute;
    top: 1;
    margin-left: -9.9%;
    color: #23aeeb;
    font-size: 1.5rem;
    z-index: 80;
}
.single__execution > h6 {
    font-size: .8rem;
    opacity: 75%;
    font-weight: 300;
}

.single__execution > h5 {
    font-size: 1.1rem;
    margin-bottom: 15px;
}

.single__execution > p {
    font-size: .9rem;
    line-height: 30px;
    font-weight: 300;
}
