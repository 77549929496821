.languages {
    background: #fff;
    color: #000;
    
}

.l-content {
    padding: 5rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 22px;
    padding-right: 22px;
    max-width: 1276px;
}
.top-text {
    display: grid;
}
.top-text > h3 {
    color: #000;
}
.top-text > h3 > a {
    color: rgb(0, 128, 255);
}
.l-link {
    display: grid;
}
.l-link > h3 {
    color: #000;
}
.l-link > h3 > a {
    color: rgb(0, 128, 255);
}
.bottom-text {
    display: grid;
}
.bottom-text > h3 {
    color: #000;
}
.button-rows {
    display: flex;
    justify-content: space-between;
}

.button-ts {
    background-color: #e7e7e7; 
    color: rgb(100, 98, 98);
    padding: 14px;
    border-radius: 23px;
}
.button-ts:hover {
    color: #000;
    background-color: #cacaca; 
}
.button-ts:active {
    background-color: rgb(0, 132, 255);
}