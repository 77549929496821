.navbar {
    background: rgb(0, 0, 0);
    height: 55px;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0; 
    position: absolute;
    margin: 0;
    width: 100%;
}

/* Logo */
.navbar__logo {
    display: block;
    background-position: center;
    margin: 0 auto;
}
.navbar__logo > a > img {
    width: 150px;
    display: flex;
}

.navbar__icon {
    display: none;
}

/* Link Container */
.navbar__links {
    width: 1000px;
    margin: 0 auto;
    padding: 0 8px;
}

.navbar__links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin-bottom: 0px;
}
.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin-bottom: 0px;
    width: 1000px;
    margin: 0 auto;
    padding: 0 8px;
}
.nav__container > p {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    opacity: 1;
    transition: opacity 800ms;
    margin-bottom: 0px;
    display: flex;
}


.nav__container > p:hover {
    opacity: 1;
}

/* Icons */
.navbar__menu {
    display: flex;
    background-position: center;
    height: 44px;
    margin-left: -1rem;
    width: 15px;
    background-repeat: no-repeat;
}
.account__menu {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    
}
.account__menu > svg:hover{
    cursor: pointer;
}

/* Logo */
.space-photo{
    display: block;
    background-position: center;
    margin: 0 auto;
    top: -10%;
    width: 250px;
}


/* Icon Divider */
.icon__div {
    width: 30px
}

/* Button Hover */
.navbar__sign > button:hover {
    background: #0095ff;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}
.navbar__sign > button > p {
    color: black;
    font-size: 14px;
    margin: 0.5rem;
}


/* Menu */
.navbar__menu {
    justify-content: flex-start;
    display: none;
}
.menu > button:hover {
    background: white;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}

.menu > button {
    padding: 0rem 0.5rem 0rem;
    background: #0095ff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    border-radius:15px;
    border: 0;
    outline: none;
    cursor: pointer;
}
/* Menu Icon */
.navbar__menu > svg {
    cursor: pointer;
}
/* Menu Container */
.nav-menu__container {display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    background-color: black;
    padding: 2rem;
    position: fixed;
    top: 42px;
    min-width: 100%;
    flex: 1;
    margin: 0.8rem -0.85rem 5rem;
}

.nav-menu__container > p {
    margin: 1rem 0;
}

.menu__links > p {
    display: block;
    text-align: left;
    color: #fff !important;
}
/* Menu */
.navbar__menu {
    justify-content: flex-start;
    display: none;
    transition-property: transform, top;
    transition-delay: 0ms, 0ms;
    transition-duration: 100ms;
}
.menu > button:hover {
    background: white;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}

/* Menu Icon */
.navbar__menu > svg {
    cursor: pointer;
}
.account__menu {
    height: 100%;
    display: block;
    background-position: center;
    margin: 0 auto;
    right: 0; 
}
.account__menu > svg{
    height: 100%;
    display: block;
    background-position: center;
    margin: 0 auto;
    right: 0; 
}
.account-menu__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: rgb(3, 3, 7);
    font-family: var(--font-family);
   
}
.account__links {
    margin-top: 10%;
    margin-bottom: 50%;
}

.account__links > a {
    display: block;
    align-items: center;
    justify-content: center;
    color: #fff !important;
}
.account__links a :hover {
    color: #23aeeb !important;
}


/* Media Query */
@media screen and (max-width: 1390px) {
    .navbar {
        width: 100%;
        padding: 0 30px;
    }
    .navbar__logo {
        display: none;
    }
    .navbar__icon {
        display: flex;
        object-fit: contain;
    }
    .navbar__icon > a > img { 
        width: 40px;
        display: flex;
    }
    .navbar__links-container {
        flex: 0.7;
    }
    .account__menu > svg {
        display: none;

    }
}


@media screen and (max-width: 1133px) {
    .navbar {
        top: 0;
        left: 0;
        width: 100%;
        justify-content: start;
        transition: left 1000ms ease;
    }
    .navbar__icon {
        display: block;
        background-position: center;
        margin: 0 auto;
        
    }
    .navbar__links-container {
        display: flex;
        width: 1px;
        height: 2px;
        z-index: 1;
        
    }
    .nav__container > p {
        display: none;
    }
    .navbar__menu {
        left: 0;
        color: #fff;
        position: absolute;
        width: 48px;
        z-index: 10;
        display: flex;
        margin: 0rem 0 2rem 1rem;
        opacity: 1;
    }
    .account__menu {
        display: none;
    }
    
}
