.service-menu {
    display: flex;
    top: 0;
    padding: 1rem 10rem 0.5rem 10rem;
    width: 100%;
    z-index: 999;
    justify-content: space-between;
    align-items: center;
    background: white;
    justify-content: space-between;
    outline: 0.1px solid white;
   
}
.service-menu__links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Link Container */
.service-menu__container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 2%; 
}
.link-item > p {
    color: black;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize; 
    margin:  0 1rem;
    cursor: pointer;
}

.link-item > img {
    height: 75px;
    width: 100%;
    margin-bottom: 10px;
}
/* Sign Up Button */
.service-menu__container > button  {
    padding: 0.5rem 0rem 1.5rem 0rem;
    width: 8%;
    border: 0;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    border-radius: 30px;
    background: none;
    cursor: pointer;
    margin-bottom: -2%;
}

/* Log In Button */
.service-buttons  {
    display: flex;
    margin: 20px -10px;
    width: 8%;
    justify-content: space-between;
    
}
.service-buttons > button {
    padding: 0.5rem 1.3rem 0rem;
    background: #23aeeb;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    border-radius: 25px;
    border: 0;
    outline: none;
    cursor: pointer;
    margin-bottom: -2%;
}

.service-menu__container > button > p {
    color: black;
}
/* Button Hover */
.service-menu__sign > button:hover {
    background: #0c47c7;
    border: 1px solid black;
    cursor: pointer;
}
.service-menu__sign > button > p > a:hover {
    color: #fff;
}

/* Menu */
.service__menu-bar {
    justify-content: flex-end;
    display: none;
    position: relative;
    z-index: 100;
}

.service-menu > button {
    padding: 0.5rem 1.3rem 0rem;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}
/* Menu Icon */
.service-menu__menu{
    color: black;
}
/* Menu Container */
.menu__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 24px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    font-family: var(--font-family);
}

.menu__container > p {
    margin: 1rem 0;
}

.service__menu-links {
    display: block;
    margin: 2px  0;
}


/* Media Query */
@media screen and (max-width: 1640px) {
    .service__menu {
        padding: 0.7rem 8rem 1rem 6rem;
        width: 100%;
       
    }
    .service__menu-logo {
        padding-right: 0px;
    }
    .service__menu-logo > a > img {
        display: flex;
        object-fit: contain;
    }
    .service__links-container {
        display: flex;
    }
    .service__menu-bar {
        display: none;
    }
    .service__menu-sign {
        display: flex;
    }
}
@media screen and (max-width: 1204px) {
    .service__links-container {
        display: none;
    }
    .service__menu-bar{
        display: flex;
    }
    .service__menu-sign {
        display: none;
    }
}
@media screen and (max-width: 390px) {
    .service__menu {
        top: 0;
        width: 100%;
        position: fixed;
        padding: 1rem 2rem 1rem 1.5rem;
    }
    .navbar__logo > a > img {
        display: flex;
        object-fit: contain;
        width: 100%;
    }
    .service__links-container {
        display: none;
    }
    .service__menu-bar{
        display: flex;
    }
    .service__menu-sign {
        display: none;
    }
}
