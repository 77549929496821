.main-store-page .container-rcdp {
    max-width: 1300px;
}

.main-store-page {
    margin-top: 8%;
}


.main-store-page .bg-inner-crcdp {
    height: 500px;
}

.webappsimage {
    background-image: url(../../assets/web-apps.jpg);
}

.mobileappsimage {
    background-image: url(../../assets/mobile-apps.jpg);
}

.softwaresaas {
    background-image: url(../../assets/software-saas.jpg);
}

.ecommercemagento {
    background-image: url(../../assets/ecommerce-magento.jpg);
}

.artifical-intelligence {
    background-image: url(../../assets/artifical-intelligence.jpg);
}

.blockhcain-nft {
    background-image: url(../../assets/blockchain-nft.jpg);
}

.virtual-reality {
    background-image: url(../../assets/virtual-reality.jpg);
}

.wearable-apps {
    background-image: url(../../assets/wearable-apps.jpg);
}

.device-integration {
    background-image: url(../../assets/device-integration.jpg);
}

.etheware-earth {
    background-image: url(../../assets/etheware-earth.jpg);
}

.etheware-bronze {
    background-image: url(../../assets/etheware-bronze.jpg);
}

.etheware-silver {
    background-image: url(../../assets/etheware-silver.jpg);
}

.etheware-gold {
    background-image: url(../../assets/etheware-gold.jpg);
}

.diamond-peak {
    background-image: url(../../assets/diamond-peak.jpg);
}

.platinum-unlimited {
    background-image: url(../../assets/platinum-unlimited.jpg);
}

.magento-plan {
    background-image: url(../../assets/magento-plan.jpg);
}

.main-store-page .pickupmodalcontainer .bg-inner-crcdp {
    height: 300px;
}

.main-store-page .pickupmodalcontainer .inner-container-rcdp {
    border: none;
    font-weight: bold;
    text-align: center;
}

.main-store-page .pickupmodalcontainer .title-bgicrc {
    font-size: 26px;
    color: black !important;
}

.model-pick-buttons {
    margin-top: 15px;
}

.model-pick-buttons button {
    background-color: rgb(123, 123, 255);
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-right: 10px;
    padding: 8px 0px;
    min-width: 80px;
    border-radius: 20px;
}

.model-pick-buttons a {
    color: rgb(123, 123, 255);
    text-decoration: none;
    font-weight: 500;
}

.model-pick-buttons a:hover {
    color: rgb(123, 123, 255);
}

.model-pick-buttons button a svg {
    font-weight: 600;
    font-size: 14px;
}

.short-desc-bgicrc {
    color: rgb(151, 149, 149);
    font-size: 20px;
    margin-bottom: 2px;
}

.main-store-page .container-rcdp.pickupmodalcontainer {
    max-width: 1500px;
}

.main-store-page .container-rcdp.pickupmodalcontainer .inner-container-rcdp {
    max-width: 400px;
}