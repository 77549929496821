.spacebar {
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.835);
    height: 55px;
    position: fixed;
    width: 100%;
    z-index: 9998;
    top: 0;
}
.spacebar > .sp-su_btn {
    border-radius: 25px;
    margin-right: 10%;
    margin-top: 3%;
    background: #0096FF;
    color: white;
    font-weight: 300;
    font-size: 20px;
    width: 7%;
    border: 1px solid black;
    outline: none;
    height: 45px;
}
.spacebar > .sp-su_btn:hover {
    background: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
    transform: scale(1.03);
}


/* Logo */
.spacebar__logo {
    padding: 0.1% 0% 0% 3%;
}
.spacebar__logo > a > h1{
    width: 500px;
    margin: none;
    padding: 0.1% 0% 0% 3%;
}
/* Media Query */

@media screen and (max-width: 1195px) {
    .spacebar {
        width: 100%;
    }
    .spacebar >.sp-su_btn >button{
        margin-top: 0.4%;
        width: 100%;
        font-size: 10px;
    }
}
@media screen and (max-width: 770px) {
    .spacebar {
        width: 100%;
    }
    .spacebar__logo > a > h1 {
        width: 100%;
    }
    .spacebar >.sp-su_btn > button{
        margin-top: 0.4%;
        font-size: 15px;
        margin-right: 20%;
        height: 40px;
    }
}
@media screen and (max-width: 507px) {
    .spacebar {
        width: 100%;
    }
    .spacebar__logo > a > h1 {
        width: 100%;
        font-size: 30px;
    }
    .spacebar >.sp-su_btn > button{
        margin-top: 0.4%;
        font-size: 20px;
        margin-right: 10%;
        height: 30px;
        width: 100%;
    }
}