.ripple {
    display: flex;
    flex-direction: row;
    padding: 2rem 1rem 1rem 6rem;
    font-family: var(--font-family);
    max-width: 100%;
    background: whitesmoke;
    max-height: 1500px;
}
/* Text & Images */
.ripple__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
    margin-top: 7%;
    
}
.ripple__content > h1 {
    font-size: 50px !important;
    color: black;
    font-weight: 700;
}
/* Paragraph */
.ripple__content > p {
    font-size: 20px;
    color: black;
    margin-top: 1.5rem;
}
.ripple__content > h2 {
    font-size: 22px;
    color: black;
    margin: 1.5rem 0 2rem 0;
}
.ripple__btns > button {
    background-color: transparent !important;
    font-family: var(--font-family);
    font-weight: 600;
    padding: 0.5rem 1rem;
    color: black;
    border-radius: 23px;
}
.ripple__btns:hover {
    background-color: rgb(239, 239, 239);
    transform: scale(1.02);
    border-radius: 23px;
}
/* Main Header Image */
.header__image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.ripple__image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Media Query */

@media screen and (max-width: 1399px) {
    .ripple {
        flex-direction: column;
        overflow: show
    }
    
    .ripple__content > h1 {
        font-size: 40px;
        line-height: 75px;
        width: 100%;
    }
    .ripple__content > h2 {
        font-size: 20px;
        line-height: 35px;
    }
    .ripple__content > p {
        font-size: 16px;
        line-height: 30px;
    }
    .ripple__image > img {
        align-items: center;
        margin: 10% -5%;
    }
}

@media screen and (max-width: 400px) {
    .ripple {
        flex-direction: column;
        width: 100%;
        margin-left: -10%;
    }
    .ripple__image > img {
        align-items: center
    }
    .ripple__content > h1 {
        font-size: xx-large;
        line-height: 60px;
        width: 190px;
    }
    .ripple__content > h2 {
        font-size: large;
        line-height: 35px;
        width: 180px;
    }
    .ripple__content > p {
        font-size: 16px;
        line-height: 30px;
        width: 160px;
    }
}