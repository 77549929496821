.header-main-dashboard {
    width: 100%;
    height: 90px;
    background-color: #0c0c0c;
}

.container-dashboard-page {
    width: 100%;
    display: flex;
    max-width: 90%;
    margin: 0px auto;
    margin-top: 30px;
}

.left-container-dp {
    width: 100%;
    max-width: 250px;
    padding-top: 60px;
}

.right-container-dp {
    flex-grow: 1;
}

.right-container-dp h4 {
    font-size: 32px;
}

.container-rcdp {
    width: 100%;
    max-width: 1000px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
}


.inner-container-rcdp {
    width: 100%;
    max-width: 350px;
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 30px;
}


a > .inner-container-rcdp:hover {
    transform: scale(1.03);
    animation-duration: 100ms;
}

.content-inner-crcdp {
    padding: 15px;
}

.bg-inner-crcdp {
    width: 100%;
    height: 190px;
    background-image:url(../../assets/order-etherware.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-inner-crcdp {
    width: 100%;
    height: 190px;
    background-image: url(../../assets/order-etherware.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.title-bgicrc {
    font-weight: 500;
    color: #b9b7b7;
    margin-bottom: 2px;
}

.desc-bgicrc {
    margin-bottom: 0px;
    color: black;
}

.desc-bgicrc b {
    color: black;
}

.content-inner-crcdp a {
    color: #b9b7b7;
}

.content-inner-crcdp a:hover {
    color: #b9b7b7;
}

.reserveimagebg {
    background-image: url(../../assets/reserve-especialist.jpeg);
}

.purchasedapplication {
    background-image: url(../../assets/purchased-application.jpeg);
}

@media only screen and (max-width: 767px) {
    .container-dashboard-page {
        flex-direction: column;
    }

    .left-container-dp {
        max-width: 100%;
        display: flex;
        padding-top: 0px;
    }

    .heading-to-hidemobile {
        display: none;
    }

    .inner-container-rcdp {
        max-width: 45%;
    }

    .right-container-dp h4 {
        font-size: 22px;
    }
}


@media only screen and (max-width: 600px) {
    .inner-container-rcdp {
        max-width: 100%;
        margin-right: 0px;
    }
}