.trusted {
    background: #27282c;
}

.trusted-content {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 5rem;
}

.trusted-text {
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    padding: 5rem;
}

.trusted-text > h1 {
    color: white;
    font-size: 43px;
    font-family: var(--font-family);
    font-weight: 700;
    line-height: 95px;
    letter-spacing: 0.1rem;
    color: var(--gradient-text);
}

.trusted-text > h3 {
    color: white;
    font-size: 29px;
    font-family: var(--font-family);
    font-weight: 300;
    line-height: 95px;
    letter-spacing: 0.04em;
}
.trusted-stats {
    display: flex;
    flex-direction: row;
    padding: 5rem;
}
.stat-1 {
    margin-right: 50px;
}
.stat-1 > h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 60px;
    line-height: 95px;
    letter-spacing: 0.02rem;
}
.stat-1 > p {
    font-size: 20px;
}
.stat-2 {
    margin-right: 50px;
}
.stat-2 > h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 60px;
    line-height: 95px;
    letter-spacing: 0.02rem;
}
.stat-2 > p {
    font-size: 20px;
}
.stat-3 {
    margin-right: 50px;
}
.stat-3 > h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 60px;
    line-height: 95px;
    letter-spacing: 0.02rem;
}
.stat-3 > p {
    font-size: 20px;
}
.stat-4 {
    margin-right: 50px;
}
.stat-4 > h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 60px;
    line-height: 95px;
    letter-spacing: 0.02rem;
}
.stat-4 > p {
    font-size: 20px;
}

.trusted-banner {
    background-color: #fff;
    margin-top: 50px;
}
.banner {
    display: flex;
}
.banner > img {
    object-fit: contain;
    width: 900px;
}
.banner-text {
    display: flex;
    flex-direction: column;
    padding: 5rem 5rem 5rem 5rem;
    width: max-content;
}
.banner-text > p {
    color: black;
    font-size: 25px;
    font-family: var(--font-family);
    font-weight: 300;
    line-height: 45px;
    width: 100%;
}
.banner-text > h2 {
    color: rgb(0, 132, 255);
    font-size: 29px;
    font-family: var(--font-family);
    font-weight: 300;
    margin-bottom: 20px;
}
.trusted-bttm {
    background-color: black;
    padding: 1%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.trusted-bttm > h3 {
    color: white;
    font-size: 28px;
    font-family: var(--font-family);
    font-weight: 300;
    line-height: 40px;
    margin: 5rem 5rem;
}
.companies {
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.companies > div {
    flex: 2;
    max-width: 250px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 980px) {
    .trusted-text {
        color: white;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
    }
    
    .trusted-text > h1 {
        color: white;
        font-size: 43px;
        font-family: var(--font-family);
        font-weight: 700;
        line-height: 95px;
        letter-spacing: 0.1rem;
        color: var(--gradient-text);
    }
    
    .trusted-text > h3 {
        color: white;
        font-size: 29px;
        font-family: var(--font-family);
        font-weight: 300;
        line-height: 95px;
        letter-spacing: 0.04em;
    }
    .trusted-banner {
        display: flex;
        flex-direction: column;
    }
    .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .banner > img {
        object-fit: contain;
        width: 200px;
    }

    .banner-text {
        display: flex;
        flex-direction: column;
        padding: 5rem 10rem 5rem 5rem;
        width: 100%;
    }
    .banner-text > p {
        font-size: 23px;
    }
    .banner-text > h2 {
        font-size: 24px;
    }
    .trusted-bttm {
        background-color: black;
        padding: 1%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .trusted-bttm > h3 {
        color: white;
        font-size: 20px;
        font-family: var(--font-family);
        font-weight: 300;
        line-height: 40px;
        margin: 5rem 5rem;
    }
    .companies {
        flex-direction: column;
    }

}
@media screen and (max-width: 560px) {
    .trusted-text {
        color: white;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
    }
    
    .trusted-text > h1 {
        color: white;
        font-size: 35px;
        font-family: var(--font-family);
        font-weight: 700;
        line-height: 95px;
        letter-spacing: 0.1rem;
        color: var(--gradient-text);
    }
    
    .trusted-text > h3 {
        color: white;
        font-size: 24px;
        font-family: var(--font-family);
        font-weight: 300;
        line-height: 95px;
        letter-spacing: 0.04em;
    }
    .trusted-banner {
        display: flex;
        flex-direction: column;
    }
    .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .banner > img {
        object-fit: contain;
        width: 200px;
    }

    .banner-text {
        display: flex;
        flex-direction: column;
        padding: 5rem 10rem 5rem 5rem;
        width: 100%;
    }
    .banner-text > p {
        font-size: 20px;
        width: 220px;
    }
    .banner-text > h2 {
        font-size: 20px;
    }
    .trusted-bttm {
        background-color: black;
        padding: 1%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .trusted-bttm > h3 {
        color: white;
        font-size: 18px;
        font-family: var(--font-family);
        font-weight: 300;
        line-height: 40px;
        margin: 5rem 5rem;
    }

}
@media screen and (max-width: 480px) {
    .trusted-text {
        color: white;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
    }
    
    .trusted-text > h1 {
        color: white;
        font-size: 35px;
        font-family: var(--font-family);
        font-weight: 700;
        line-height: 95px;
        letter-spacing: 0.1rem;
        color: var(--gradient-text);
    }
    
    .trusted-text > h3 {
        color: white;
        font-size: 20px;
        font-family: var(--font-family);
        font-weight: 300;
        line-height: 95px;
        width: 200px;
        letter-spacing: 0.04em;
    }
    .trusted-banner {
        display: flex;
        flex-direction: column;
    }
    .banner-text > p {
        font-size: 17px;
        width: 200px;
    }
    .banner {
        width: 100%;
        flex: 1;
    }
    .companies {
        flex-direction: column;
    }

}
@media screen and (max-width: 300px) {
    .banner-text > p {
        font-size: 16px;
        width: 185px;
    }

}