.details {
    display: flex;
    background: #000;
    align-items: center;
    justify-content: center;
    height: 800px;
}
.details__container {
    position: relative; 
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0%);

}
.details__container {
    position: relative; 
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0%);

}
.details__image-containe {
    height: 8000px;
}