/* Main Header Background */
.header {
    display: flex;
    flex-direction: row;
    padding: 2rem 1rem 1rem 6rem;
    font-family: var(--font-family);
    max-width: 100%;
    background: var(--color-bg);
    max-height: 1500px;
}
/* Text & Images */
.header__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
    padding: 5rem;
    
}
/* Main Header 1 */
.header__content > h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 70px;
    line-height: 105px;
    letter-spacing: -0.04em;
    color: var(--gradient-text);
}

.gradient__text {
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04rem;
} 
/* Subheader */
.header__content > h2 {
    font-weight: normal;
    font-style: normal;
    font-size:  25px;
    line-height: 50px;
    letter-spacing: -0.04rem;
    color: white;
}

/* Paragraph */
.header__content > p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

/* Email Input form */
.header-form {
    width: 100%;
    margin: 2rem 0 1rem;
    flex-direction: row;
}

.header-form > input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

form > .header-btn {
    flex: 0.6;
    margin-bottom: 0%;
    width: 100%;
    font-family: var(--font-family);
    font-weight: 400;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
/* User Images */
.header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.header-content__people > img {
    width: 181.79px;
    height: 38px;
}

.header-content__people > p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}
/* Main Header Image */
.header__image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.header__image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Media Query */

@media screen and (max-width: 1650px) {
    .header__content {
        margin-top: 10%;
        max-width: 100%;
        padding: 2rem;
    }
    .header__content > h1 {
        font-size: 80px;
    }
    .header__content > p {
        font-size: 20px;
    }
    .header__content > h2 {
        font-size: 22px;
        font-weight: 400;
    }
}
@media screen and (max-width: 1200px) {
    .header { 
        display: flex;
        padding: 2rem 1rem 1rem 6rem;
        max-width: 100%;
        flex-direction: column;
    }
    .header__content > h1 {
        font-size: 70px;
        width: auto;
    }
    .header__content > p {
        font-size: 30px;
        width: auto;
    }
    .header__content > h2 {
        font-size: 25px;
        width: auto;
    }
    .header-content__people {
        display: flex;
        flex-direction: column;
    }
    .header__image > img {
        width: 600px;
        height: 600px
    }  
    .header__image  {
        width: 100%;
    }  
}

@media screen and (max-width: 919px) {
    .header__image > img {
        width: 100%;
    }  
    .header { 
        margin-bottom: 35%;
    }
    .header__content > h1 {
        font-size: 50px;
        font-weight: 900;
        width: fit-content;
    }
    .header__content > h2 {
        font-size: 20px;
        width: 100%;
    }
    .header__content > p {
        font-size: 20px;
        width: 100%;
    }
}
@media screen and (max-width: 650px) {
    .header__image > img {
        width: 100%;
        height: 400px;
    }  
    .header { 
        margin-bottom: 40%;
    }
    .header__content > h1 {
        font-size: 45px;
        font-weight: 900;
        width: fit-content;
    }
    .header__content > h2 {
        font-size: 25px;
        width: 100%;
    }
    .header__content > p {
        font-size: 25px;
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .header__image  {
       margin-left: -10%;
    }  
    .header__image > img {
        width: 400px;
        height: 500px;
    }  
    .header { 
        margin-bottom: 40%;
    }
    .header__content {
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .header__content > h1 {
        font-size: 45px;
        width: 350px;
        line-height: 65px;
    }
    .header__content > h2 {
        font-size: 22px;
        width: 350px;
    }
    .header-content__input {
        width: 260px;
    }
    .header-content__people {
        width: 260px;
    }
    .header__content > p {
        font-size: 20px;
        width: 350px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
@media screen and (max-width: 400px) {
    .header__image  {
       margin-left: -20%;
    }  
    .header__image > img {
        width: 250px;
        height: 200px;
    }  
    .header { 
        margin-bottom: 40%;
    }
    .header__content {
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .header__content > h1 {
        font-size: 35px;
        width: 205px;
        line-height: 45px;
    }
    .header__content > h2 {
        font-size: 17px;
        width: 205px;
    }
    .header-content__input {
        width: 260px;
    }
    .header-content__people {
        width: 260px;
    }
    .header__content > p {
        font-size: 17px;
        width: 202px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
