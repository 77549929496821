.case-studies {
}

.portfolio>.row {
    margin-top: 0%;
}

@media screen and (max-width: 930px) {
    .case__portfolio {
        padding-top: 20%;
    }
}

.case-studies .content__top > h3 {
    color: white;
}

@media screen and (max-width: 590px) {
    .case-studies .media__container {
        margin: 0px auto;
    }
}

.case-studies .portfolio__card .content__top > h6 {
    color: #23aeeb;
}