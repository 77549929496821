/* Brands */
.brand {
    display: flex;
    background-color: black;
    padding: 1%;
    margin-bottom: 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
/* Brands Container */
.brand > div {
    flex: 2;
    max-width: 250px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Media Query */
@media screen and (max-width: 900px) {
    .brand {
        width: 100%;
        flex-direction: column;
        
    }
}
@media screen and (max-width: 390px) {
    .brand {
        margin-top: 25%;
        display: flex;
        width: 100%;  
    }
}