.info__container {
    background-color: var(--gradient-bar);
    color: white;
    padding: 120px;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.info__container > .info {
    text-align: left;
}
.info__container > .info > h2 {
    color: #58acf1;
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 24px;
    color: var(--color-text);
}
.info__container > .info > p {
    font-family: var(--font-family);
    line-height: 24px;
    padding: 10px;
    color: rgb(164, 137, 214);
}
.info__container > .info > h1 {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 38px;
    line-height: 54px;
    color: white;
}

/* Media Query */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 900px) {
    .info__container {
        padding: 30px 0px 0px 0px;
        width: 100%;
        margin-top: 10%;
    }
    .info__container > .info > h2 {
        font-size: 1.3rem
    }
    .info__container > .info > h1 {
        font-size: large;
    }
}

