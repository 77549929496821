/* Button CSS */
.interactive__button {
    width: 100%;
    padding: 20px;
    color: white;
    background: transparent;
    border: none;
    outline: none;
}

.interactive__buttons > button {
    background: black;
    border-radius: 1px;
    padding: 32px;
    width: 100%;
    border: 1px solid #23aeeb;
}


.interactive__buttons > button:hover {
    background: #23aeeb;
}
.interactive__buttons > button:active {
    background: #000;
}


/* Wrapper for Image and content CSS */
.interactive__content {
    flex-direction: column;
    margin-left: 20%;
}
.interactive__content__wrapper {
    background-color: #132347;
    align-items: center;
    padding: 20px;
    margin-top: 20%;
    border-radius: 20px;
}
.interactive__content > h2 {
    margin-bottom: 15px;
}

.interactive__content > p {
    line-height: 30px;
    font-weight: 300;
}

.social__links span a i {
    color: #23aeeb;
}

.social__links span {
    margin-right: 15px;
}


/* IMG CSS */
.interactive__img  {
    background: #23aeeb;
}
.interactive__img > img {
    display: flex;
    object-fit: contain;
    width: 400px;
    height: 100%;
}

@media screen and (max-width: 1399px) {
    .interactive__content__wrapper {
        flex-direction: column;
        margin-top: 10%;
    }
    .interactive__content {
        flex-direction: column;
        margin-left: 0%;
    }
}
@media screen and (max-width: 500px) {
    .interactive__img > img {
        width: 300px;
        height: 100%;
    }
}
@media screen and (max-width: 300px) {
    .interactive__img > img {
        width: 200px;
        height: 100%;
    }
}
