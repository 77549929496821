.display {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

}
.display__container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -150%);

}
.display__container h1 {
    color: #0096FF;
    font-size: 98px;
}
.display__container a{
    position: relative;
    
}
.display__text {
    background-color: black;
    display: flex;
    padding: 10%;
   
}
.display__text div{
    height: 80px;
   
}
.demo {
    background: white;
}
.display__text > h1 {
    color: white;
    font-size: 64px;
    font-weight: 700;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}
.display__text > p {
    color: rgb(183, 183, 183);
    font-size: 34px;
    font-weight: 700;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}
.display__text > .display__color {
    color: #0096FF;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: -.0045em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.space__text > p {
    color: #0096FF;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: -.0025em;
    line-height: 13rem;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}
.space__text > h3 {
    color: #c7c7c8;
}
.space__text {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 10%;
    height: 1000px;
   
}
.space__text div{
    height: 80px;
   
}
.neon__text {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    padding: 10%;
    height: 1000px;
   
}
.display__text > h1 {
    color: white;
    font-size: 64px;
    font-weight: 700;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}
.display__text > .display__color {
    color: #0096FF;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: -.0045em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

@media screen and (max-width: 480px) {
    .display__text {
        font-size: xx-large;
    }
    .display__text > h1 {
        font-size: xx-large;
    }
    .display__text > .display__color {
        font-size: 30px;
    }
}