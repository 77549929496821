.innovations__wrapper {
    background-color: #162638;
    padding: 20px;
    border-radius: 10px;
}

.skill__bar {
    width: 100%;
    position: relative;
    z-index: 99;
    border-radius: 50px;
    height: 5px;
    background: #fff;
}

.skill__bar-percentage {
    background: #23aeeb;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    height: 5px;
    border-radius: 50px;
}

.skill__title > span {
    color: #fff;
}

.skill__title {
    margin-bottom: 10px;
}

@media screen and (max-width: 650px) {
    .innovations__wrapper {
        width: 100%;
        flex-direction: column;
    }
}

