/* Styling for the Lighter backgrounds, main difference is the color of the text. */
.light__module {
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex: 2;
    width: 100%;
}

.light-module__bg {
    display: flex;
    background: #fff;
    
}

.light-module__img {
    object-fit: contain;
    display: flex;
    max-width: 5000px;
    align-items: center;
    justify-content: center;
    flex: 2;
}

.md-light-module__img {
    display: none
}

.sm-light-module__img {
    display: none
}
.light-module__content {
    position: absolute;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0.55em;     
    top: 13%;                       
}



/* Media Query */

@media screen and (max-width: 1732px) {
    .light-module__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
    
    .light-module__img {
        display: flex;
        object-fit: contain;
        width: 100%;

    }
}
@media screen and (max-width: 735px) {
    .light-module__bg {
        display: flex;
    }
    
    .light-module__img {
        display: none;
    }
    .md-light-module__img {
        display: none;
    }
    .sm-light-module__img {
        display: flex;
        object-fit: contain;
        width: 100%;
    }
}

