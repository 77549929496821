.sign-in-bg {
    background-color: white;
}
.container-signup {
    width: 100%;
    max-width: 530px;
    margin: 0px auto;
    padding-top: 50px;
    padding-bottom: 40px;
    align-items: center;
    justify-content: flex-end;
    
    
}
.container-signup > img {
    width: 100px;
    height: 100%;
    margin-top: 20%;
    margin-bottom: 10%;
    margin-left: 40%;
}

.container-signup p {
    font-size: 24px;
    font-weight: 300;
    width: 100%;
    color: black;
    align-items: center;
    margin-left: 10%;
}
.container-signup h3 {
    font-size: 34px;
    line-height: 45px;
    letter-spacing: 0.6px;
    font-weight: 500;
    color: black;
    padding-bottom: 40px;
}

.container-form-parent {
    width: 100%;
    max-width: 332px;
    margin: 0px auto;
}

.container-forms {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: black;
}

.container-forms label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.414;
    padding-left: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
}

.select-boxes-holder {
    width: 100%;
    position: relative;
}

.select-boxes-holder svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
    font-size: 20px;
    fill: black;
    color: black;
}

.container-forms input, .container-forms select {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid #f5f5f5;
    font-weight: 500;
    outline-color: #dddcdc;
    width: 100%;
    color: black;
}

.container-forms select {
    appearance: none;
}

.tooltip-icon {
    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width='50px' height='50px'%3E%3Cpath d='M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z'/%3E%3C/svg%3E");
    width: 15px;
    height: 15px;
    color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    margin-left: 5px;
}

.create-account-button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: white;
    border: 1px solid black;
    background-color: #3d69e1;
    border-radius: 32px;
    margin: 0px auto;
    margin-top: 20px;
    width: 100%;
    display: block;
    padding: 7px 24px;
}
.create-account-button:hover {
    color: white;
    background-color: #318cf4;
}

.separator-main {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.separator-main label {
    font-size: 15px;
    font-weight: 500;
    font-size: 18px;
}

.horizontal-line {
    width: 40%;
    height: 1px;
    margin: 0 8px;
    background-color: rgb(209, 206, 206);
}

.sign-in-button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: black;
    background-color: transparent;
    border-radius: 32px;
    border: 3px solid 1px;
    outline: none;
    margin: 0px auto;
    width: 100%;
    margin-top: 20px;
    display: block;
    padding: 7px 24px;
    transition: all .3s ease-in-out;
    color: black;
}

.sign-in-button:hover {
    background-color: rgb(202, 201, 201);
    color: black;
}

@media only screen and (max-width: 500px) {
    .container-signup > img {
        width: 200px;
        height: 200px;
        align-items: center;
        justify-content: center;
    }
    .container-signup {
        width: 100%;
        max-width: 90%;
    }

    .container-form-parent {
        max-width: 100%;
    }

    .container-signup h3 {
        font-size: 22px;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}