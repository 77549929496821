/* Box Container CSS */
.box__container {
    height: 950px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem;
    margin-left: 0;
}

.box__wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 0px;
}

/* Box Card CSS */
.boxCard {
    background-color: black;
    flex-direction: flex-start;
    align-items: center;
    height: 450px;
    padding: 30px;
    border: 2px solid #23aeeb;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.boxCard:hover {
    transform: scale(1.03);
    transition: all 0.2s ease-in-out;
    background: rgb(23, 56, 148);
}

/* Icon CSS */
.boxCard > img {
    height: 180px;
    width: 100%;
    margin-bottom: 10px;
}


/* Subheader CSS */
.boxCard > h2 {
    color: #23aeeb;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 18px;
    margin-bottom: 10px;
}

/* Paragraph Css */
.boxCard > p {
    text-align: left;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 18px;
    color: white;
}

/* Media Query */

/* Small-medium screens and displays under 768px */
@media screen and (max-width: 868px) {
    .box__container {
        height: 100%;
    }
    .boxCard {
        width: 100%;
        height: 100%; 
    }
    .box__wrapper {
        grid-template-columns: 1fr;
    }
}
/* Large screens, dekstops, TV's over 1000px */
